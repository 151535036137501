import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import backgroundSVG from "./assets/HeaderBackground.svg";
import MainPage from "./pages/Main_Page";
import BlogDetailPage from "./pages/Blog_Detail_Page";
import BlogPage from "./pages/Blog_Page";
import IletisimPage from "./pages/Iletisim_Page";
import KariyerPage from "./pages/Kariyer_Page";
import KurumsalPage from "./pages/Kurumsal_Page";
import Sektorler_Page from "./pages/Sektorler_Page";
import SurdurulebilirlikPage from "./pages/Surdurulebilirlik_Page";
import UretimPage from "./pages/Uretim_Page";
import UrunlerPage from "./pages/Urunler_Page";
import Blog_User_Page from "./pages/Blog_User_Page";

import Main_Data from "./components/DB_converter";
import VideoPlayer from "./components/VideoPlayer"; // Video bileşeni
import "./App.css";

const SektorItems = Main_Data.SektorItems;

const App = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    console.log("Spinner started");
    const spinnerTimeout = setTimeout(() => {
      console.log("Spinner finished, starting video");
      setShowSpinner(false);
      setShowVideo(true);
    }, 3500);

    return () => clearTimeout(spinnerTimeout);
  }, []);

  const handleVideoEnd = () => {
    console.log("Video ended, showing content");
    setShowVideo(false); // Video bittiğinde içeriği göster
  };
  

  const routes = SektorItems.map((item) => (
    <Route
      key={item.way}
      path={`/${item.way}`}
      element={
        <Sektorler_Page change_page={(label) => console.log(label)} sektor_item={item.name} />
      }
    />
  ));

  return (
    <Router basename="/">
      {/* Video oynuyorken App-container devre dışı */}
      <div
        className={showVideo ? "" : "App-container"} // Eğer video oynuyorsa className boş bırakılır
        style={{
          position: "relative",
          backgroundImage: showVideo ? "none" : `url(${backgroundSVG})`, // Video oynuyorsa arka plan kaldırılır
        }}
      >
        {/* Spinner */}
        {showSpinner && (
          <div className="loading_bar">
            <div
              className="spinner-container"
              style={{
                width: "250px",
                height: "250px",
              }}
            >
              <div className="spinner">
                <div className="spinner">
                  <div className="spinner">
                    <div className="spinner">
                      <div className="spinner">
                        <div className="spinner"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Video */}
        {showVideo && <VideoPlayer onVideoEnd={handleVideoEnd} />}

        {/* Sayfa İçeriği */}
        {!showSpinner && !showVideo && (
          <div className="App">
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/kurumsal" element={<KurumsalPage />} />
              <Route path="/uretim" element={<UretimPage />} />
              <Route path="/urunler" element={<UrunlerPage />} />
              <Route path="/kariyer" element={<KariyerPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:blog_id" element={<BlogDetailPage />} />
              <Route path="/surdurulebilirlik" element={<SurdurulebilirlikPage />} />
              <Route path="/iletisim" element={<IletisimPage />} />
              <Route path="/Blog_User_Page" element={<Blog_User_Page />} />
              {routes}
            </Routes>
          </div>
        )}
      </div>
    </Router>
  );
};

export default App;
