import React from "react";

const VideoPlayer = ({ onVideoEnd }) => {
  console.log("VideoPlayer rendered");

  const handleContextMenu = (e) => {
    e.preventDefault(); // Sağ tıklamayı engelle

  };

  //Test//
  // let vid = document.getElementById("intro");
  // vid.playbackRate = 10.5;

  return (
    <div
      onContextMenu={handleContextMenu} // Sağ tıklama olayı dinlenir ve engellenir
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        zIndex: 100,
      }}
    >
      <video 
      id="intro"
        autoPlay
        playsInline
        muted
        onEnded={onVideoEnd} // Video bittiğinde tetiklenir
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src="/video/istanbulMabsan02LQ.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
